import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Form, Button, Spinner, Card, Collapse } from "react-bootstrap";
import Axios from "axios";
import logo from "../images/surveywand_logo.png"; // Import the image

function URLInputPage() {
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [analyzing, setAnalyzing] = useState(false);
  const [providingProblem, setProvidingProblem] = useState(false);
  const [resultText, setResultText] = useState(null);
  const [surveyProblem, setSurveyProblem] = useState("");
  const [surveyGuidelines, setSurveyGuidelines] = useState("The survey should be 5 to 7 questions long, use pagination if needed, have a description, survey in English.");
  const [showProblemSection, setShowProblemSection] = useState(false);
  const [surveyCriteria, setSurveyCriteria] = useState(null);
  const [backgroundInfoError, setBackgroundInfoError] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Simulate loading time
    setTimeout(() => {
      setLoading(false);
    }, 1000); // Adjust the timeout as needed
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setAnalyzing(true);
    setError(null);

    try {
      const response = await Axios.post("/scrape-website", { url });
      const result = response.data.data.summary;
      setResultText(result);
      localStorage.setItem("surveyCompany", result);
      setShowProblemSection(true);
    } catch (error) {
      setError("Error scraping website. Please enter the survey criteria manually.");
    }
    setAnalyzing(false);
  };

  const handleSkip = () => {
    setResultText("");
    setShowProblemSection(true);
  };

  const handleProblemSubmit = async (event) => {
    event.preventDefault();

    // Validate Background Information
    if (resultText === "") {
      setBackgroundInfoError(true);
      return;
    } else {
      setBackgroundInfoError(false);
    }

    setProvidingProblem(true);
    setError(null);

    try {
      const surveyCompany = resultText;
      localStorage.setItem("surveyCompany", surveyCompany);
      localStorage.setItem("surveyProblem", surveyProblem);
      localStorage.setItem("surveyGuidelines", surveyGuidelines);
      const response = await Axios.post("/create-prompt", { surveyCompany, surveyProblem, surveyGuidelines });
      console.log("Prompt created:", response.data.data);
      const criteria = response.data.data.criteria;
      setSurveyCriteria(criteria);
      console.log('in here', criteria)
      localStorage.setItem("surveyCriteria", criteria);
      navigate("/magic-survey", { state: { criteria } });
    } catch (error) {
      setError("Error creating prompt. Please try again.");
    }
    setProvidingProblem(false);
  };

  if (loading) {
    return (
      <Container fluid className="d-flex flex-column align-items-center justify-content-center vh-100 p-4 bg-light">
        <Spinner animation="border" variant="primary" />
        <h4 className="mt-3">Loading, please wait...</h4>
      </Container>
    );
  }

  return (
    <Container fluid className="d-flex flex-column align-items-center justify-content-center vh-100 p-4 bg-light">
      <div style={{ marginTop: "75px" }}></div>
      <Row className="w-100 mb-4 justify-content-center">
        <Col md={10} lg={8}>
          <Card className="shadow-sm rounded-lg">
            <Card.Body>
            <img src={logo} alt="Logo" style={{ height: '50px', marginBottom: '20px' }} />
            <div className="d-flex align-items-center mb-3">
               
                <h5 className="text-dark m-0">pythanks is ready to create a magical survey for you. We just need a few pieces of information.</h5>
              </div>
              <hr />
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="urlInput" className="mb-3">
                  <Form.Label className="fw-bold">Website URL <small>Include https:// </small></Form.Label>
                  <Form.Control type="url" value={url} onChange={(e) => setUrl(e.target.value)} placeholder="https://example.com" required className="mt-2 p-2 rounded" />
                </Form.Group>
                <Button variant="primary" type="submit" disabled={analyzing} className="mt-3 w-100 py-2 rounded">
                  {analyzing ? (
                    <>
                      <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> Analyzing Website...
                    </>
                  ) : (
                    "Analyze Website"
                  )}
                </Button>
              </Form>
              <Button variant="secondary" onClick={handleSkip} className="mt-3 w-100 py-2 rounded">
                I don't want to analyze a website
              </Button>
              {error && <div className="text-danger mt-3">{error}</div>}
              <Collapse in={showProblemSection}>
                <div>
                  {resultText !== null && (
                    <Form.Group controlId="resultTextarea" className="mt-4">
                      <Form.Label className="fw-bold">Background Information</Form.Label>
                      <Form.Control
                        as="textarea"
                        value={resultText}
                        onChange={(e) => {
                          setResultText(e.target.value);
                          localStorage.setItem("surveyCompany", e.target.value);
                        }}
                        rows={5}
                        className="mt-2 p-2 rounded"
                        placeholder="Please provide some background information regarding your organization or the issue at hand"
                        required
                      />
                      {backgroundInfoError && <div className="text-danger mt-2">This field is required.</div>}
                    </Form.Group>
                  )}
                  <Form onSubmit={handleProblemSubmit} className="mt-4">
                    <Form.Group controlId="problemInput" className="mb-3">
                      <Form.Label className="fw-bold">Problem to Solve</Form.Label>
                      <Form.Control
                        as="textarea"
                        value={surveyProblem}
                        onChange={(e) => setSurveyProblem(e.target.value)}
                        placeholder="Tell me the specific problem you want to solve. For example, We want to measure our NPS, or we want to understand customer interest in a new product called X, or we want feedback on our customer service."
                        required
                        rows={3}
                        className="mt-2 p-2 rounded"
                      />
                    </Form.Group>
                   
                    <Form.Group controlId="guidelinesInput" className="mb-3 mt-4">
                      <Form.Label className="fw-bold">Additional Survey Guidelines (How many questions, types of questions, language - all languages are supported.)</Form.Label>
                      <Form.Control
                        as="textarea"
                        value={surveyGuidelines}
                        onChange={(e) => setSurveyGuidelines(e.target.value)}
                        placeholder="The survey should be 5 to 7 questions long, use pagination if needed, have a description, survey in English."
                        required
                        rows={3}
                        className="mt-2 p-2 rounded"
                      />
                    </Form.Group>
                    <Button variant="primary" type="submit" disabled={providingProblem} className="mt-3 w-100 py-2 rounded">
                      {providingProblem ? (
                        <>
                          <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> Generating Instructions...
                        </>
                      ) : (
                        "Generate Magic Survey Instructions"
                      )}
                    </Button>
                  </Form>
                </div>
              </Collapse>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default URLInputPage;

